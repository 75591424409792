@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Qwigley&family=Smooch&family=Tangerine:wght@400;700&display=swap");

body {
  margin: 0;
  font-family: "Josefin Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @apply selection:bg-bg-cold-700 selection:text-bg-cold-200 h-screen;
}

input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: transparent;
}

#root {
  height: inherit;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.page-enter {
  opacity: 0;
  transform: scale(1.1);
}

.page-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 300ms, transform 300ms;
}

.page-exit {
  opacity: 1;
  transform: scale(1);
}

.page-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

// Navigation styles
ul.nav {
  li a {
    @apply border-b border-b-white rounded-none;
  }
  .active {
    @apply border-b border-b-accent rounded-none text-accent;
  }
}

// Tabs styles
.nav-tabs {
  .nav-link {
    @apply text-bg-cold-600 transition-colors duration-300;
    &:hover {
      @apply text-bg-cold-800 bg-bg-cold-200;
    }
    &.active {
      @apply border-b border-b-accent text-accent;
    }
  }
}

input[type="file"] {
  @apply text-bg-cold-500 active:text-accent-300 focus:text-bg-cold;
  &#tabbedFileInput {
    @apply ml-4 text-xs flex items-center justify-center;
    &::file-selector-button {
      @apply transition-all duration-300 border text-xs border-primary-300 float-right px-2 py-1.5 mb-2 ml-2 mr-0 rounded-md bg-primary-300 text-white hover:bg-primary-200 hover:cursor-pointer active:bg-primary-500;
    }
  }
}

input[type="file"] {
  text-align: end;
  // -webkit-appearance: none;
  // text-align: left;
  // -webkit-rtl-ordering: left;

  &::-webkit-file-upload-button {
    // -webkit-appearance: none;
    // float: right;
    // @apply flex flex-row justify-self-end self-end;
    // margin: 0 0 0 10px;
    // border: 1px solid #aaaaaa;
    // border-radius: 4px;
    // background-image: -webkit-gradient(linear, left bottom, left top, from(#d2d0d0), to(#f0f0f0));
    // background-image: -moz-linear-gradient(90deg, #d2d0d0 0%, #f0f0f0 100%);
  }
}

input[type="range"] {
  height: 33px;
  -webkit-appearance: none;
  // margin: 10px 0;
  width: 100%;
  margin: 7px 0 0;

  &:focus {
    outline: none;
  }
  &::-webkit-slider-runnable-track {
    @apply w-full h-2.5 cursor-pointer bg-bg-cold-200 rounded-2xl border border-solid border-bg-cold-400;
  }
  &::-webkit-slider-thumb {
    @apply bg-accent-300 w-6;
    &:active {
      @apply bg-accent-500;
    }
  }
}

input[type="range"] input[type="range"]::-webkit-slider-thumb {
  // box-shadow: 1px 1px 1px #000000;
  // border: 2px solid #000000;
  // height: 24px;
  // width: 40px;
  // border-radius: 25px;
  // background: #ff3874;
  // cursor: pointer;
  // -webkit-appearance: none;
  // margin-top: -8.5px;
}
input[type="range"]:focus::-webkit-slider-runnable-track {
  // background: #3071a9;
  @apply bg-bg-cold-300;
}
input[type="range"]::-moz-range-track {
  width: 100%;
  // height: 10px;
  cursor: pointer;
  // animate: 0.2s;
  box-shadow: 1px 1px 1px #000000;
  // background: #3071a9;
  @apply bg-bg-cold-300;
  border-radius: 15px;
  border: 1px solid #000000;
}
input[type="range"]::-moz-range-thumb {
  box-shadow: 1px 1px 1px #000000;
  border: 2px solid #000000;
  height: 24px;
  width: 40px;
  border-radius: 25px;
  background: #ff3874;

  cursor: pointer;
}
input[type="range"]::-ms-track {
  width: 100%;
  height: 10px;
  cursor: pointer;
  animate: 0.2s;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
input[type="range"]::-ms-fill-lower {
  // background: #3071a9;
  @apply bg-bg-cold-600;
  border: 1px solid #000000;
  border-radius: 30px;
  box-shadow: 1px 1px 1px #000000;
}
input[type="range"]::-ms-fill-upper {
  // background: #3071a9;
  @apply bg-bg-cold-600;
  border: 1px solid #000000;
  border-radius: 30px;
  box-shadow: 1px 1px 1px #000000;
}
input[type="range"]::-ms-thumb {
  margin-top: 1px;
  box-shadow: 1px 1px 1px #000000;
  border: 2px solid #000000;
  height: 24px;
  width: 40px;
  border-radius: 25px;
  background: #ff3874;
  cursor: pointer;
}
input[type="range"]:focus::-ms-fill-lower {
  // background: #3071a9;
  @apply bg-bg-cold-300;
}
input[type="range"]:focus::-ms-fill-upper {
  // background: #3071a9;
  @apply bg-bg-cold-300;
}

.stylistic-quote-mark {
  font-size: 5rem;
  right: 95%;
  @apply mr-2 hidden font-display-2 font-normal text-secondary-50 absolute top-0 leading-none;
}

@screen sm {
  .stylistic-quote-mark {
    @apply block;
  }
}

/* Remove input[type="number"] arrows */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}

// input[type="search"]::-webkit-search-cancel-button {
//   @apply relative appearance-none h-5 w-5;
//   // position: relative;
//   right: 20px;

//   // -webkit-appearance: none;
//   height: 20px;
//   width: 20px;
//   border-radius: 10px;
//   background: green;
// }

// input[type="search"] {
// border: 1px solid gray;
// padding: 0.2em 0.4em;
// border-radius: 0.2em;
// }

// input[type="search"].dark {
//   background: #222;
//   color: #fff;
// }

// input[type="search"].light {
//   background: #fff;
//   color: #222;
// }

input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  height: 1em;
  width: 1em;
  border-radius: 50em;
  background: url(https://pro.fontawesome.com/releases/v5.10.0/svgs/solid/times-circle.svg) no-repeat 50% 50%;
  background-blend-mode: color-dodge;
  background-size: contain;
  opacity: 0.05;
  pointer-events: none;
}

input[type="search"]:focus::-webkit-search-cancel-button {
  opacity: 0.3;
  pointer-events: all;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
