.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.content {
  display: flex;
  height: 300px;
  color: #fff;
  overflow: hidden;

  .col {
    position: relative;
    overflow: hidden;
    flex-grow: 1;
    flex-basis: 0;
    transition: flex-grow 0.3s;

    transform: skew(20deg, 0);
    background: var(--bg-dark);

    & + .col {
      border-left: 5px solid var(--primary);
    }
    &:first-child {
      margin-left: -100px;
      flex-grow: 2;
    }
    &:last-child {
      margin-right: -100px;
    }
    &:hover {
      flex-grow: 2;
      transition: flex-grow 0.3s;
      &:last-child {
        flex-grow: 3;
      }
    }
    &::before {
      content: "";
      position: absolute;
      height: 100%;
      width: 600px;
      margin-left: -100px;
      display: block;
      background-attachment: fixed;
      transform: skew(-20deg, 0);
    }
    &:nth-child(1)::before {
      margin-left: 100px;
    }

    h1 {
      margin: 0;
      padding: 10px;
      transform: skew(-20deg, 0);
    }
    &:first-child h1 {
      margin-left: 40px;
    }
  }
}
